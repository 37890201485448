/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IFSalesMode } from 'src/app/components/Features/QuickService/QuickService.component';
import { buttonClass } from 'src/app/components/Features/Table/Table.component';
import { GButton } from 'src/app/components/Libs';
import { RootState } from 'src/setup';
import {ArrowLeft, ArrowRight} from 'react-swm-icon-pack'


const ListSalesMode: React.FC<{setSalesMode:(sales:IFSalesMode)=>void, salesMode:IFSalesMode, type: 'DINE_IN'| 'TAKE_AWAY'}> = ({setSalesMode, salesMode, type}) => {
    const [listSalesMode, setListSalesMode] = useState([])
    const dataBranch:  any = useSelector<RootState>(({auth}) => auth?.dataBranch, shallowEqual)
    const [currentPage, setCurrentPage] = useState<number>(1)
      const itemPerPage = 3

    useEffect(()=>{
        if(listSalesMode.length === 0) {
            const salesList = dataBranch?.sales_mode_id?.filter((item:IFSalesMode)=> item.status === 'ACTIVE' && type==='TAKE_AWAY' ? item.show_quick_service : item.show_dine_in)
            if(salesList?.length > 0) setListSalesMode(salesList)
        }
    }, [listSalesMode, dataBranch?.sales_mode])

    return (
        <div className='flex flex-col items-start relative '>
            <div className='flex flex-row justify-between w-full mb-2'>
                    <span className='text-sm mb-2 h-auto self-center'>Sales Mode</span>
                    <div className='flex flex-row justify-end gap-2'>
                      <GButton
                        className={clsx(
                          'disabled:opacity-40 self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
                        )}
                        variant='UNSTYLED'
                        size='small'
                        data-testid='back-button'
                        disabled={currentPage === 1}
                        onClick={() => {
                          if (currentPage > 0) {
                            setCurrentPage((e) => e - 1)
                          }
                        }}
                      >
                        <ArrowLeft color='#CF1E26' />
                      </GButton>
                      <GButton
                        className={clsx(
                          'disabled:opacity-40 self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
                        )}
                        variant='UNSTYLED'
                        size='small'
                        data-testid='back-button'
                        disabled={currentPage === Math.ceil((listSalesMode ?? [])?.length / itemPerPage)}
                        onClick={() => {
                          if (currentPage <= Math.ceil((listSalesMode ?? []).length / itemPerPage)) {
                            setCurrentPage((e) => e + 1)
                          }
                        }}
                      >
                        <ArrowRight color='#CF1E26' />
                      </GButton>
                    </div>
                  </div>
            <div className=' gap-2 w-full grid grid-cols-3'>
                {listSalesMode && listSalesMode?.map((sales:any, index:number)=>{
                    return (
                        <button
                        data-cy={`sales-mode-${index}`}
                            key={index}
                            onClick={() => setSalesMode(sales)}
                            className={`${buttonClass(salesMode?.id === sales.id)} w-50`}
                            >
                                {sales.name.slice(0,11)}{sales.name.length > 7 ? '...':''}
                        </button>
                    )
                })
                }
            </div>
        </div>
    );
};

export default ListSalesMode;
