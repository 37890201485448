/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {IVisitor} from 'src/app/components/Features/Table/models/Table.model'
import {buttonClass} from 'src/app/components/Features/Table/Table.component'
import {GButton} from 'src/app/components/Libs'
import {ArrowLeft, ArrowRight} from 'react-swm-icon-pack'
import clsx from 'clsx'

const ListVisitorComponent: React.FC<{
  visitors: IVisitor | undefined
  listVisitor: IVisitor[] | null
  onSelecetVisitor: (visitor: IVisitor) => void
  type: 'DINE_IN' | 'TAKE_AWAY'
}> = ({visitors, listVisitor, onSelecetVisitor, type}) => {
  const [listVisitorType, setListVisitorType] = useState<IVisitor[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const itemPerPage = 3

  useEffect(() => {
    if (listVisitor && listVisitor.length >= 0) {
      const listVisitorTypeHolder = listVisitor?.filter((item: IVisitor) =>
        item.status === 'ACTIVE' && type === 'TAKE_AWAY'
          ? item.show_quick_service
          : item.show_dine_in
      )
      setListVisitorType(listVisitorTypeHolder)
    }
  }, [])

  useEffect(() => {
    console.log('listVisitorType', listVisitor)
  }, [listVisitorType])

  return (
    <div className='flex flex-col items-start'>
      <div className='flex flex-row justify-between w-full mb-2'>
        <span className='text-sm mb-2 h-auto self-center'>Visitor Type</span>
        <div className='flex flex-row justify-end gap-2'>
          <GButton
            className={clsx(
              'disabled:opacity-40 self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
            )}
            variant='UNSTYLED'
            size='small'
            data-testid='back-button'
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage > 0) {
                setCurrentPage((e) => e - 1)
              }
            }}
          >
            <ArrowLeft color='#CF1E26' />
          </GButton>
          <GButton
            className={clsx(
              'disabled:opacity-40 self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
            )}
            variant='UNSTYLED'
            size='small'
            data-testid='back-button'
            disabled={currentPage === Math.ceil((listVisitor ?? [])?.length / itemPerPage)}
            onClick={() => {
              if (currentPage <= Math.ceil((listVisitor ?? []).length / itemPerPage)) {
                setCurrentPage((e) => e + 1)
              }
            }}
          >
            <ArrowRight color='#CF1E26' />
          </GButton>
        </div>
      </div>
      <div className='gap-2 w-full grid grid-cols-3'>
        {listVisitor &&
          listVisitor
          ?.slice((currentPage - 1) * itemPerPage, currentPage * itemPerPage)
          .map((visitor: IVisitor, index: number) => {
            return (
              <button
                key={index}
                onClick={() => onSelecetVisitor(visitor)}
                className={`${buttonClass(visitors?.id === visitor.id)} w-50 px-2 min-h-fit`}
              >
                <span>
                {visitor.name.slice(0, 7)}
                {visitor.name.length > 7 ? '...' : ''}
                </span>
              </button>
            )
          })}
      </div>
    </div>
  )
}

export default ListVisitorComponent
